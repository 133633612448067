var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-container',{staticClass:"big-module-training"},[_c('b-row',{staticClass:"page-top"},[_c('b-col',{staticClass:"text-left"},[_c('span',{staticClass:"iconfont icon-tishi"}),_vm._v(" 系统已为你自动定位不同题型的训练难度等级，请根据系统指引训练即可！ ")]),_c('b-col',{directives:[{name:"show",rawName:"v-show",value:(_vm.permission.indexOf('Plys.English.MiddleSchool.CanSkip') !== -1),expression:"permission.indexOf('Plys.English.MiddleSchool.CanSkip') !== -1"}],staticClass:"text-right"},[_c('GlobalButtonParallelogram',{attrs:{"sprintId":4,"tagId":2}})],1)],1),_c('b-row',{staticClass:"page-header"},[_c('b-col'),_c('b-col',[_vm._v("七年级")]),_c('b-col',[_vm._v("八年级")]),_c('b-col',[_vm._v("九年级")]),_c('b-col',[_vm._v("预期时间")]),_c('b-col',[_vm._v("实际时间")])],1),_vm._l((_vm.bigModuleTrainings),function(item,index){return _c('b-col',{key:index,class:item.vocabularyTrainings.length < 1 ? 'page-text' : 'page-content'},[_c('b-row',{staticClass:"title-table"},[_c('b-col',{staticClass:"question-title",attrs:{"sm":"2"}},[_c('div',{staticClass:"question-type"},[_vm._v(_vm._s(item.sectionName))])]),_vm._l((item.vocabularyTrainings),function(items,c){return _c('b-col',{directives:[{name:"show",rawName:"v-show",value:(item.vocabularyTrainings.length > 1 ? false : true),expression:"item.vocabularyTrainings.length > 1 ? false : true"}],key:c},[_c('b-row',{attrs:{"align-v":"center"}},[_c('b-col',{attrs:{"v-if":items.seven != null}},[_c('GlobalButtonDirectory',{attrs:{"state":items.seven?items.seven.state:'',"starNum":items.seven?items.seven.star:''},on:{"beginTest":function($event){return _vm.toTrainingQuestions(
                  item.sectionType,
                  items.questionSet,
                  items.seven,
                  item.sectionName,
                  items.trainingExamId
                )}}})],1),_c('b-col',{attrs:{"v-if":items.eighth != null}},[_c('GlobalButtonDirectory',{attrs:{"state":items.eighth?items.eighth.state:'',"starNum":items.eighth?items.eighth.star:''},on:{"beginTest":function($event){return _vm.toTrainingQuestions(
                  item.sectionType,
                  items.questionSet,
                  items.eighth,
                  item.sectionName,
                  items.trainingExamId
                )}}})],1),_c('b-col',{attrs:{"v-if":items.nine != null}},[_c('GlobalButtonDirectory',{attrs:{"state":items.nine?items.nine.state:'',"starNum":items.nine?items.nine.star:''},on:{"beginTest":function($event){return _vm.toTrainingQuestions(
                  item.sectionType,
                  items.questionSet,
                  items.nine,
                  item.sectionName,
                  items.trainingExamId
                )}}})],1),_c('b-col',{staticClass:"estimated-time"},[_vm._v(_vm._s(items.expectedMinutes)+"分钟")]),_c('b-col',{staticClass:"actual-time"},[_vm._v(_vm._s(items.actualMinutes)+"分钟")])],1)],1)})],2),_c('div',{staticClass:"sub-content"},_vm._l((item.vocabularyTrainings),function(items,c){return _c('b-row',{directives:[{name:"show",rawName:"v-show",value:(item.vocabularyTrainings.length < 2 ? false : true),expression:"item.vocabularyTrainings.length < 2 ? false : true"}],key:c},[_c('b-col',{staticClass:"read-title",attrs:{"sm":"2"}},[_c('div',[_vm._v(_vm._s(items.trainingExamName))])]),_c('b-col',{attrs:{"v-if":items.seven != null}},[_c('GlobalButtonDirectory',{attrs:{"state":items.seven?items.seven.state:'',"starNum":items.seven?items.seven.star:''},on:{"beginTest":function($event){return _vm.toTrainingQuestions(
                item.sectionType,
                items.questionSet,
                items.seven,
                item.sectionName,
                items.trainingExamId
              )}}})],1),_c('b-col',{attrs:{"v-if":items.eighth != null}},[_c('GlobalButtonDirectory',{attrs:{"state":items.eighth?items.eighth.state:'',"starNum":items.eighth?items.eighth.star:''},on:{"beginTest":function($event){return _vm.toTrainingQuestions(
                item.sectionType,
                items.questionSet,
                items.eighth,
                item.sectionName,
                items.trainingExamId
              )}}})],1),_c('b-col',{attrs:{"v-if":items.nine != null}},[_c('GlobalButtonDirectory',{attrs:{"state":items.nine?items.nine.state:'',"starNum":items.nine?items.nine.star:''},on:{"beginTest":function($event){return _vm.toTrainingQuestions(
                item.sectionType,
                items.questionSet,
                items.nine,
                item.sectionName,
                items.trainingExamId
              )}}})],1),_c('b-col',{staticClass:"estimated-time1"},[_vm._v(_vm._s(items.expectedMinutes)+"分钟")]),_c('b-col',{staticClass:"actual-time1"},[_vm._v(_vm._s(items.actualMinutes)+"分钟")])],1)}),1)],1)})],2)}
var staticRenderFns = []

export { render, staticRenderFns }